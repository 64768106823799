export default {
    computed: {
        aKey() {
            let aKey = this.$cookies.get('a-key')
            if (!aKey) {
                aKey = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
                    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
                )
                this.$cookies.set('a-key', aKey)
            }
            return aKey
        },
    },

    methods: {
        $track(eventName, eventData = {}) {
            if (!process.client) return
            console.log('tracker.$track(): ', eventName, eventData)
            return // No tracker on admin
            const data = {
                name: eventName,
                extra: eventData || {},
                url: window.location.href,
                aKey: this.aKey,
                facebook: {
                    fbp: this.$cookies.get('_fbp') || null,
                    fbc: this.$cookies.get('_fbc') || null,
                },
            }
            try {
                // eslint-disable-next-line no-console
                return this.$axios.post('/api/v1/t', data).catch((e) => console.warn('Failed: ', e))
            } catch (e) {
                return {}
            }
        },

        $trackEvent(eventName, eventData = {}) {
            if (!process.client) return
            console.log('tracker.$trackEvent(): ', eventName, eventData)
            return // No tracker on admin
            // eslint-disable-next-line no-console
            try {
                return (
                    this.$axios
                        .post('/api/v1/e', { aKey: this.aKey, extra: eventData, event: eventName, url: window.location.href, referer: document.referrer })
                        // eslint-disable-next-line no-console
                        .catch((e) => console.warn('Failed', e))
                )
            } catch (e) {
                return {}
            }
        },

        $trackConnect() {
        },

        $eventValues(model = {}, map = {}) {
            const obj = {}
            Object.keys(map).forEach((x) => {
                if (model && model[map[x]]) obj[x] = model[map[x]]
                else obj[x] = map[x].split('.').reduce((p, c) => (p && p[c]) || null, model)
            })
            return obj
        },
    },
}

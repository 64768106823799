
import { mapState, mapMutations } from 'vuex'
import product from '@/components/pages/payment/mixins/product'

export default {
    mixins: [product],

    props: {
        small: Boolean,
    },

    data() {
        return {
            paymentMethod: null,
            cardForm: false,
            coupon: {},
            paypalTimer: null,
        }
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user || {},
            customer: (state) => state.products.customer || [],
            paymentMethods: (state) => state.products.methods,
            generatedModel: (state) => state.questionnaire.generatedModel,
        }),

        hasPaypal() {
            return process.client && window && window.paypal
        },
    },

    watch: {
        paymentMethod() {
            if (this.paymentMethod) this.cardForm = false
        },

        unitAmount: {
            immediate: true,
            handler() {
                this.initPaypal()
            },
        },
    },

    methods: {
        ...mapMutations({}),

        async pay() {
            this.$toast.info(this.content.notifications.paying_transaction).goAway(3000)
            if (!this.customer?.id) await this.$store.dispatch('products/fetchCustomer')
            try {
                this.$trackEvent('INIT_CHECKOUT', {
                    price_id: this.productObject.id,
                    product_id: this.productObject.product,
                    coupon: this.coupon?.id || null,
                })
            } catch (e) {}
            this.$axios
                .$post(`/api/v1/payments/checkout`, {
                    price_id: this.productObject.id,
                    product_id: this.productObject.product,
                    payment_method: this.paymentMethod,
                    coupon: this.coupon?.id || null,
                })
                .then(() => {
                    this.$store.dispatch('user/fetch').then(() => this.onSuccessPayment())
                })
                .catch((e) => {
                    this.$toast.error(this.content.notifications.unsuccessful_transaction.replace('%error%', e.response.data.error)).goAway(3000)
                })
        },

        onSuccessPayment() {
            this.success = true
            try {
                this.$trackEvent('PURCHASE', {
                    content_type: 'Product',
                    value: this.unitAmount,
                    currency: 'USD',
                })
            } catch (e) {}
            this.$toast.success(this.content.notifications.successful_transaction).goAway(3000)
            setTimeout(() => {
                if (this.generatedModel?.id) this.$router.push({ name: 'dashboard' })
                else if (this.$route.name === 'payment-id') this.$router.push({ name: 'payment-id-done', params: { id: this.product } })
                else if (this.$route.name === 'i-do-crew') this.$router.push({ name: 'users-username-courses', params: { username: this.user.username } })
                this.$emit('done')
            }, 1000)
        },

        toggleCardForm() {
            if (!this.cardForm) {
                this.cardForm = true
                this.paymentMethod = null
            }
        },

        getPaypalId() {
            const productObject = this.productObject || {}
            if (productObject.metadata && productObject.metadata.paypal) return productObject.metadata.paypal
            if (productObject.details && productObject.details.metadata && productObject.details.metadata.paypal) return productObject.details.metadata.paypal
            return null
        },

        handleCardInput(val) {
            this.paymentMethod = val
            this.pay()
        },

        initPaypal() {
            if (window.paypal && this.hasPaypal && this.unitAmount && this.productObject) {
                if (document.getElementById('paypal-buttons')) document.getElementById('paypal-buttons').innerHTML = ''
                const paypalId = this.getPaypalId()
                if (paypalId) {
                    clearTimeout(this.paypalTimer)
                    this.paypalTimer = setTimeout(() => {
                        this.$nextTick(() => {
                            window.paypal
                                .Buttons({
                                    style: {
                                        // shape: 'pill',
                                        color: 'gold',
                                        layout: 'vertical',
                                        // label: 'paypal',
                                    },
                                    createSubscription: (data, actions) => {
                                        return actions.subscription.create({
                                            plan_id: paypalId,
                                        })
                                    },
                                    onApprove: (data, actions) => {
                                        this.$axios
                                            .post('/api/v1/paypal/webhook', {
                                                event_type: 'BILLING.SUBSCRIPTION.CREATED',
                                                resource: {
                                                    id: data.subscriptionID,
                                                },
                                            })
                                            .then(() => {
                                                this.$store.dispatch('user/fetch').then(() => this.onSuccessPayment())
                                            })
                                    },
                                })
                                .render('#paypal-buttons')
                        })
                    }, 1000)
                }
            }
        },
    },
}

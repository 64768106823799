import { render, staticRenderFns } from "./FooterSocialLinks.vue?vue&type=template&id=75713584&lang=pug"
import script from "./FooterSocialLinks.vue?vue&type=script&lang=js"
export * from "./FooterSocialLinks.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default})


import iconMixin from '~/mixins/iconMixin'

export default {
    mixins: [iconMixin],

    data() {
        return {
            icons: {},
            componentEl: 'img',
            content: null,
        }
    },

    watch: {
        iconUrl: {
            immediate: true,
            handler() {
                if (process.browser)
                    this.fetchSvg().then(svgEl => {
                        this.content = svgEl
                        this.$forceUpdate()
                    }).catch(() => this.content = null)
            }
        }
    },

    mounted() {
        if (process.browser) this.componentEl = 'svg'
    },

    methods: {
        fetchSvg() {
            return new Promise((resolve, reject) => {
                const request = new XMLHttpRequest()
                request.open('GET', this.iconUrl, true)
                request.onload = () => {
                    if (request.status >= 200 && request.status < 400) {
                        try {
                            const parser = new DOMParser()
                            const result = parser.parseFromString(request.responseText, 'image/svg+xml')
                            let svgEl = result.getElementsByTagName('svg')[0]
                            if (svgEl) resolve(svgEl)
                            else reject(new Error('No SVG found in response'))
                        } catch (e) { reject(e) }
                    } else reject(new Error('Request failed: ' + request.statusText))
                }
                request.onerror = reject
                request.send()
            })

        }
    }
}

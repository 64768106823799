
import capitalize from '@/mixins/capitalize'
import { mapState } from 'vuex'
import authLogic from './authLogic'

export default {
    mixins: [authLogic, capitalize],

    props: {
        inputClasses: {
            type: String,
            default: '',
        },
        noFlip: Boolean,

        modal: Boolean,
    },

    data() {
        return {
            loading: false,
        }
    },

    computed: {
        ...mapState({
            globalTexts: (state) => state.jsonloader.data['global.json'] || null,
        }),

        subHeading() {
            if (this.isStyleQuiz) {
                return 'Sign in to see your results.'
            } else if (this.product?.id) {
                return 'Login to complete your payment.'
            } else {
                return this.globalTexts?.login?.subtitle || ''
            }
        },
    },

    methods: {
        loginPassword() {
            this.loading = true
            this.$store
                .dispatch('user/loginPassword', { username: this.model.email, password: this.model.password })
                .then((res) => {
                    this.doAfterLogin(res)
                })
                .catch(() => {})
                .finally(() => {
                    this.loading = false
                })
        },
    },
}

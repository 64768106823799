// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/get-started-bg.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".flip-card[data-v-e7617846]{height:39rem;perspective:1000px;width:25rem}.flip-card-inner[data-v-e7617846]{height:100%;position:relative;transform-style:preserve-3d;transition-duration:.5s;transition-property:all;transition-timing-function:cubic-bezier(.4,0,.2,1);width:100%}.flip-card-inner.flipped[data-v-e7617846]{transform:rotateY(180deg)}.flip-card-inner.flipped .front[data-v-e7617846]{pointer-events:none;z-index:-1}.flip-card-inner .back[data-v-e7617846],.flip-card-inner .front[data-v-e7617846]{backface-visibility:hidden;height:100%;position:absolute;width:100%}.flip-card-inner .back[data-v-e7617846]{transform:rotateY(180deg)}.get-started[data-v-e7617846]{background-repeat:no-repeat;background-size:contain;border-radius:.75rem;height:100%;padding:8rem 2.5rem 2rem;text-align:left;--tw-bg-opacity:1;background-color:#f4ede9;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:25rem}@supports (color:rgb(0 0 0/0)){.get-started[data-v-e7617846]{background-color:rgb(244 237 233/var(--tw-bg-opacity))}}.switch-enter-active[data-v-e7617846],.switch-leave-active[data-v-e7617846]{transition:all .5s}.switch-enter[data-v-e7617846],.switch-leave-to[data-v-e7617846]{opacity:0;transform:rotateY(90deg)}@media (max-width:576px){.get-started[data-v-e7617846]{background-image:none;padding:0}.flip-card[data-v-e7617846],.get-started[data-v-e7617846]{height:auto;width:100%}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/get-started-bg.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".get-started[data-v-75bf4564]{background-repeat:no-repeat;background-size:contain;border-radius:.75rem;padding:8rem 1.25rem 1.25rem;--tw-bg-opacity:1;background-color:#f4ede9;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:25rem}@supports (color:rgb(0 0 0/0)){.get-started[data-v-75bf4564]{background-color:rgb(244 237 233/var(--tw-bg-opacity))}}@media (max-width:576px){.get-started[data-v-75bf4564]{background-image:none;height:auto;padding:0;width:100%}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;


import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState({
            contentTypes: (state) => state.data.contentTypes || [],
        }),

        types() {
            return this.contentTypes.filter((el) => el.is_taxonomy === false)
        },

        typeIds() {
            return this.types.reduce((acc, el) => {
                if (el.children && el.children.length) el.children.forEach((c) => acc.push(c.id))
                acc.push(el.id)
                return acc
            }, [])
        },

        taxonomies() {
            return this.contentTypes.filter((el) => el.is_taxonomy === true)
        },

        taxonomyIds() {
            return this.taxonomies.reduce((acc, el) => {
                if (el.children && el.children.length) el.children.forEach((c) => acc.push(c.id))
                acc.push(el.id)
                return acc
            }, [])
        },
    },

    methods: {
        logout() {
            this.$auth.logout().then(() => {
                this.$router.replace({ name: 'index' })
            })
        },
    },
}

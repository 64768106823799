
export default {
    props: {
        login: {
            type: Boolean,
        },
    },

    data() {
        return {
            isLoginForm: this.login,
        }
    },
}

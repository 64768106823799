import { render, staticRenderFns } from "./MobileMenu.vue?vue&type=template&id=7bf23864&lang=pug"
import script from "./MobileMenu.vue?vue&type=script&lang=js"
export * from "./MobileMenu.vue?vue&type=script&lang=js"
import style0 from "./MobileMenu.vue?vue&type=style&index=0&id=7bf23864&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlyButton: require('/app/components/inputs/Button.vue').default,LoverlyAvatar: require('/app/components/elements/Avatar.vue').default,LoverlyMegaMenu: require('/app/components/menus/megamenu/MegaMenu.vue').default,LoverlySidebar: require('/app/components/elements/Sidebar.vue').default})

import { render, staticRenderFns } from "./default.vue?vue&type=template&id=6cb62f52&scoped=true&lang=pug"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=6cb62f52&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cb62f52",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyPageHeader: require('/app/components/elements/PageHeader.vue').default,LoverlyFooterSocialLinks: require('/app/components/elements/FooterSocialLinks.vue').default,LoverlyInput: require('/app/components/inputs/Input.vue').default,LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlyInputIcon: require('/app/components/inputs/InputIcon.vue').default,LoverlyFooter: require('/app/components/elements/Footer.vue').default,LoverlyUserSidebar: require('/app/components/elements/user/sidebar/UserSidebar.vue').default})

import { render, staticRenderFns } from "./AdminPageHeader.vue?vue&type=template&id=5d1478ad&scoped=true&lang=pug"
import script from "./AdminPageHeader.vue?vue&type=script&lang=js"
export * from "./AdminPageHeader.vue?vue&type=script&lang=js"
import style0 from "./AdminPageHeader.vue?vue&type=style&index=0&id=5d1478ad&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d1478ad",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyLogo: require('/app/components/elements/Logo.vue').default,LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlyAvatar: require('/app/components/elements/Avatar.vue').default})


export default {
    name: 'AdminNavigationItem',

    props: {
        list: {
            type: Array,
            default: () => [],
        },

        route: {
            type: String,
            default: 'internal-admin-type',
        },
    },
}

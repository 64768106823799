import Vue from 'vue'
import debounce from 'lodash.debounce'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../tailwind.config'
const fullConfig = resolveConfig(tailwindConfig)
class Resolution {
    constructor() {
        this._size = 'xs'
        this._isMobile = false
        this._mobileBreakpoint = 'md-max'
    }

    get size() {
        return this._size
    }

    set size(val) {
        this._size = val
    }

    get isMobile() {
        return this._isMobile
    }

    set isMobile(val) {
        this._isMobile = val
    }

    get mobileBreakpoint() {
        return this._mobileBreakpoint
    }
}
const resolution = Vue.observable(new Resolution())

const screens = Object.entries(fullConfig.theme.screens).reduce((p, c) => {
    if (!c[1].max) p.push([c[0], parseInt(c[1].replace('px', ''))])
    return p
}, [])

const screensMax = Object.entries(fullConfig.theme.screens).reduce((p, c) => {
    if (c[1].max) p[c[0]] = parseInt(c[1].max.replace('px', ''))
    return p
}, {})


const resizeHandler = debounce((e) => {
    watchResolution(e.target.screen.width)
}, 100)

function watchResolution(width) {
    resolution.size = 'xs'
    screens.forEach(([res, value]) => {
        resolution.isMobile = width <= screensMax[resolution.mobileBreakpoint]
        if ((value <= width && width <= screensMax[`${res}-max`]) || (value <= width && !screensMax[`${res}-max`])) resolution.size = res
    })
}

window.addEventListener('resize', resizeHandler)

export default (context, inject) => {
    inject('res', resolution)
}


import openable from '~/mixins/openable'
export default {
    mixins: [openable],

    props: {
        right: {
            type: Boolean,
            default: true,
        },
    },

    methods: {
        toggle() {
            this.isOpen = !this.isOpen
        },
    },
}

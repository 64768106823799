const newContent = { name: null, excerpt: null, data: { blocks: [] }, content_type: { slug: null }, metas: {}, tags: [], taxonomy: [], cover: null }

export const state = () => ({
    content: JSON.parse(JSON.stringify(newContent)) || {},
    success: true,
    saving: false,
})

export const mutations = {
    set(state, data) {
        state.content = data
        state.success = true
    },

    error(state) {
        if (!state.content && !state.content.slug) state.content = JSON.parse(JSON.stringify(newContent))
        state.success = false
    },

    setProp(state, params) {
        if (!state.content) state.content = {}
        if (state.content) state.content[params.prop] = JSON.parse(JSON.stringify(params.value))
    },
}

export const actions = {
    async fetch({ commit }, params) {
        try {
            const res = await this.$axios.$get(`/api/v1/contents/admin/${params.type}/${params.slug}`)
            commit('set', res.data)
        } catch (e) {
            commit('error')
        }
    },

    async save({ commit, state }) {
        try {
            let res
            if (state.content.id) res = await this.$axios.$put(`/api/v1/contents/admin/${state.content.content_type.id}/${state.content.id}`, state.content)
            else res = await this.$axios.$post(`/api/v1/contents/admin/${state.content.content_type.id}`, state.content)
            commit('set', res.data)
            return res.data
        } catch (e) {
            commit('error')
            throw e
        }
    },

    async publish({ commit, state }) {
        try {
            const res = await this.$axios.$post(`/api/v1/contents/admin/${state.content.content_type.id}/${state.content.id}/publish`, state.content)
            commit('set', res.data)
        } catch (e) {
            commit('error')
        }
    },

    async schedule({ commit, state }, date) {
        try {
            const res = await this.$axios.$post(`/api/v1/contents/admin/${state.content.content_type.id}/${state.content.id}/schedule`, { date })
            commit('set', res.data)
        } catch (e) {
            commit('error')
        }
    },

    async unpublish({ commit, state }) {
        try {
            const res = await this.$axios.$post(`/api/v1/contents/admin/${state.content.content_type.id}/${state.content.id}/unpublish`, state.content)
            commit('set', res.data)
        } catch (e) {
            commit('error')
        }
    },

    clear({ commit }) {
        commit('set', JSON.parse(JSON.stringify(newContent)))
    },

    set({ commit }, params) {
        commit('setProp', params)
    },
}

import { render, staticRenderFns } from "./RegisterForm.vue?vue&type=template&id=14bdfb80&scoped=true&lang=pug"
import script from "./RegisterForm.vue?vue&type=script&lang=js"
export * from "./RegisterForm.vue?vue&type=script&lang=js"
import style0 from "./RegisterForm.vue?vue&type=style&index=0&id=14bdfb80&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14bdfb80",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyHeading: require('/app/components/elements/typography/Heading.vue').default,LoverlyInput: require('/app/components/inputs/Input.vue').default,LoverlyInputPassword: require('/app/components/inputs/InputPassword.vue').default,LoverlyLoadingSpinner: require('/app/components/elements/LoadingSpinner.vue').default,LoverlyButton: require('/app/components/inputs/Button.vue').default,LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlyAgreement: require('/app/components/elements/Agreement.vue').default})

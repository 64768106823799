import { render, staticRenderFns } from "./MegaMenuItem.vue?vue&type=template&id=42bb48c5&lang=pug"
import script from "./MegaMenuItem.vue?vue&type=script&lang=js"
export * from "./MegaMenuItem.vue?vue&type=script&lang=js"
import style0 from "./MegaMenuItem.vue?vue&type=style&index=0&id=42bb48c5&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyMegaMenuImage: require('/app/components/menus/megamenu/MegaMenuImage.vue').default,LoverlyMegaMenuBlock: require('/app/components/menus/megamenu/MegaMenuBlock.vue').default,LoverlyImage: require('/app/components/elements/Image.vue').default,LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default})

import { render, staticRenderFns } from "./admineditor.vue?vue&type=template&id=3604697a&lang=pug"
import script from "./admineditor.vue?vue&type=script&lang=js"
export * from "./admineditor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyAdminPageHeader: require('/app/components/admin/AdminPageHeader.vue').default})

const middleware = {}

middleware['adminguard'] = require('../middleware/adminguard.js')
middleware['adminguard'] = middleware['adminguard'].default || middleware['adminguard']

middleware['homepage'] = require('../middleware/homepage.js')
middleware['homepage'] = middleware['homepage'].default || middleware['homepage']

middleware['premiumguard'] = require('../middleware/premiumguard.js')
middleware['premiumguard'] = middleware['premiumguard'].default || middleware['premiumguard']

middleware['tools'] = require('../middleware/tools.js')
middleware['tools'] = middleware['tools'].default || middleware['tools']

export default middleware

export const state = () => ({
    wedding: {},
    model: {},
})

export const mutations = {
    setWedding(state, data) {
        state.wedding = data
    },

    setModel(state, data) {
        state.model = data
    },

    updateWedding(state, data) {
        state.wedding = { ...state.wedding, ...data }
    },

    generateModel(state, data) {
        const model = {
            name: null,
            slug: data.slug || null,
            id: data.id || 1,
            preview: true,
            excerpt: data.excerpt || '',
            content_type: data.content_type || {},
            taxonomy: data.taxonomy || [],
            children: data.children || [],
            tags: data.tags || [],
        }

        const name = `${data.spouse1FirstName} ${data.spouse1LastName} & ${data.spouse2FirstName} ${data.spouse2LastName}'s wedding`

        if (!model.slug)
            model.slug = `${name
                .toLowerCase()
                .replace(' & ', '-')
                .replace(/ /g, '-')
                .replace(/[^\w-]+/g, '')}-${data.user_id}`

        model.name = name
        model.content_type = data.content_type

        model.metas = {
            wedding_date: data.wedding_date,
            wedding_guest_count: data.guest_count,
            wedding_budget_range: data.budget_range,
            wedding_submitter_email: data.email,
            location: data.wedding_location,
            spouse_1_firstname: data.spouse1FirstName,
            spouse_1_lastname: data.spouse1LastName,
            spouse_1_type: data.spouse1Type,
            spouse_2_firstname: data.spouse2FirstName,
            spouse_2_lastname: data.spouse2LastName,
            spouse_2_type: data.spouse2Type,
            wedding_submitter_type: data.weddingSubmitterType,
            video_url: data.video_url,
        }

        model.user = data.user
        model.user_id = data.user_id

        if (data.colors?.length) model.taxonomy = [...model.taxonomy, ...data.colors]
        if (data.religion?.id) model.taxonomy = [...model.taxonomy, data.religion]
        if (data.culture?.id) model.taxonomy = [...model.taxonomy, data.culture]
        if (data.season?.id) model.taxonomy = [...model.taxonomy, data.season]
        if (data.setting?.id) model.taxonomy = [...model.taxonomy, data.setting]
        if (data.style?.id) model.taxonomy = [...model.taxonomy, data.style]
        if (data.community?.id) model.taxonomy = [...model.taxonomy, data.community]
        if (data.weddingType?.id) model.taxonomy = [...model.taxonomy, data.weddingType]

        data.vendors.map((el, i) => {
            if (el.vendor)
                model.taxonomy.push(
                    el.vendor.slug
                        ? el.vendor
                        : {
                              name: el.vendor.name,
                              slug: 'vendor-' + i,
                              url: '/vendor-' + i,
                              taxonomy: [{ name: el.service, slug: el.service, content_type: { slug: 'services' } }],
                              metas: {
                                  contact_email: el.vendor.email,
                                  contact_instagram: el.vendor.instagram,
                                  contact_url: el.vendor.website,
                              },
                              tags: [],
                              content_type: { slug: 'vendors' },
                          }
                )
        })

        data.attachments.map((el, i) => {
            model.children = [
                ...model.children,
                {
                    cover: el,
                    name: el.title ? el.title : model.name + '-image-' + (i + 1),
                    slug: el.slug ? el.slug : 'image-' + i,
                    content_type: { slug: 'wedding-inspiration' },
                    tags: [],
                    metas: {},
                    id: el.id,
                    isNew: !el.slug,
                },
            ]
        })
        state.model = model
    },

    generateFrontendModel(state, data) {
        state.wedding = {
            id: data.id,
            slug: data.slug,
            excerpt: data.excerpt,
            user_id: data.user.id,
            user: data.user,
            budget_range: data.metas.wedding_budget_range,
            email: data.metas.wedding_submitter_email,
            guest_count: data.metas.wedding_guest_count,
            wedding_date: data.metas.wedding_date,
            wedding_location: data.metas.location,
            video_url: data.metas.video_url,
            spouse1FirstName: data.metas.spouse_1_firstname,
            spouse1LastName: data.metas.spouse_1_lastname,
            spouse1Type: data.metas.spouse_1_type,
            spouse2FirstName: data.metas.spouse_2_firstname,
            spouse2LastName: data.metas.spouse_2_lastname,
            weddingSubmitterType: data.metas.wedding_submitter_type,
            spouse2Type: data.metas.spouse_2_type,
            culture: data.taxonomy.find((el) => el.content_type.slug === 'cultures') || {},
            religion: data.taxonomy.find((el) => el.content_type.slug === 'religions') || {},
            season: data.taxonomy.find((el) => el.content_type.slug === 'seasons') || {},
            setting: data.taxonomy.find((el) => el.content_type.slug === 'settings') || {},
            style: data.taxonomy.find((el) => el.content_type.slug === 'styles') || {},
            community: data.taxonomy.find((el) => el.content_type.slug === 'communities') || {},
            weddingType: data.taxonomy.find((el) => el.content_type.slug === 'wedding-type') || {},
            vendors:
                data.taxonomy
                    .filter((el) => el.content_type.slug === 'vendors')
                    .map((vendor) => {
                        const service = vendor.taxonomy.find((tax) => tax.content_type.slug === 'services')
                        return {
                            service: service?.slug,
                            vendor,
                        }
                    }) || [],
            colors: data.taxonomy.filter((el) => el.content_type.slug === 'colors') || [],
            attachments:
                data.children
                    .filter((el) => el.content_type.slug === 'wedding-inspiration')
                    .map((el) => {
                        return {
                            title: el.name,
                            slug: el.slug,
                            progress: 100,
                            ...el.cover,
                        }
                    }) || [],
        }
    },
}

export const actions = {
    generateWeddingModel({ commit }, payload) {
        commit('setWedding', payload)
        commit('generateModel', payload)
    },

    generateFrontendModel({ commit }, payload) {
        commit('setModel', payload)
        commit('generateFrontendModel', payload)
    },
}

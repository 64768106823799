
export default {
    props: {
        user: {
            type: Object,
            default: () => {},
        },
        src: {
            type: String,
            default: '',
        },
        alt: {
            type: String,
            default: '',
        },

        size: {
            type: Number,
            default: 32,
        },
    },

    computed: {
        avatarSrc() {
            if (this.user && this.user.avatar && this.user.avatar.url) return this.user.avatar.url
            if (this.user && this.user.cover && this.user.cover.url) return this.user.cover.url
            return this.src ? this.src : '/newuser.png'
        },
    },
}

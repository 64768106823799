
export default {
    props: {
        to: {
            type: null,
            default: null,
        },
        href: {
            type: null,
            default: null,
        },
        label: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'button',
        },
        disabled: Boolean,
        back: Boolean,
    },

    computed: {
        buttonClasses() {
            return ['btn', 'no-line', this.href ? 'd-inline-flex' : ''].join(' ').trim()
        },

        ariaLabel() {
            return this.label ? this.label : `button-${this._uid}`
        },
    },

    methods: {
        handleClick(e) {
            if (!this.to && !this.back) this.$emit('click', e)
            else if (this.back) this.$router.go(-1)
        },
    },

    render(h) {
        return h(
            this.to ? 'nuxt-link' : this.href ? 'a' : 'button',
            {
                class: this.buttonClasses,
                props: {
                    to: this.to ? this.to : null,
                },
                attrs: {
                    'aria-label': this.ariaLabel,
                    href: this.href ? (this.disabled ? null : this.href) : null,
                    target: this.href ? '_blank' : null,
                    disabled: this.disabled,
                    type: this.to || this.href ? undefined : this.type,
                    rel: this.href ? 'noreferrer' : '',
                    ...this.$attrs,
                },
                on: {
                    click: this.handleClick,
                },
            },
            [this.$slots.default ? this.$slots.default : this.label]
        )
    },
}

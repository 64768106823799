import { render, staticRenderFns } from "./Avatar.vue?vue&type=template&id=129540a2&scoped=true&lang=pug"
import script from "./Avatar.vue?vue&type=script&lang=js"
export * from "./Avatar.vue?vue&type=script&lang=js"
import style0 from "./Avatar.vue?vue&type=style&index=0&id=129540a2&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "129540a2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyImage: require('/app/components/elements/Image.vue').default})


import { mapState } from 'vuex'
import mobileScroll from '@/mixins/mobileScroll'

export default {
    mixins: [mobileScroll],

    data() {
        return {
            gap: 32,
        }
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user || {},
        }),

        menu() {
            return [
                { name: 'Dashboard', icon: 'home', to: { name: 'dashboard' } },
                { name: 'Checklist', icon: 'check-circle', to: { name: 'tools-wedding-checklist' } },
                { name: 'Guest List', icon: 'users', to: { name: 'tools-guest-list' } },
                { name: 'Vendor Manager', icon: 'briefcase', to: { name: 'tools-vendor-manager' } },
                { name: 'Mood Boards', icon: 'heart', to: { name: 'users-username-boards', params: { username: this.user.username } } },
                { name: '', icon: 'cog', to: { name: 'users-username-settings-type', params: { username: this.user.username, type: 'profile' } } },
            ]
        },
    },
}

export default function ({ $auth, redirect, store, route }) {
    if (route.name.includes('wedding-checklist'))
        if ($auth.loggedIn) {
            if (store?.state?.auth?.user?.checklist_setup_at) {
                if (route.name === 'tools-wedding-checklist-setup' || route.name === 'tools-wedding-checklist-setup-step') redirect('/tools/wedding-checklist')
            } else if (route.name !== 'tools-wedding-checklist-setup-step') {
                redirect('/tools/wedding-checklist/setup/1')
            }
        } else if (route.name !== 'tools-wedding-checklist-setup') redirect('/tools/wedding-checklist/setup')

    if (route.name.includes('guest-list')) {
        if ($auth.loggedIn) {
            if (store?.state?.auth?.user?.guestlist_setup_at) {
                if (route.name === 'tools-guest-list-setup' || route.name === 'tools-guest-list-setup-step') redirect('/tools/guest-list')
            } else if (route.name !== 'tools-guest-list-setup-step') {
                redirect('/tools/guest-list/setup/1')
            }
        } else if (route.name !== 'tools-guest-list-setup') redirect('/tools/guest-list/setup')
    }

    if (route.name.includes('vision-boards') || route.name.includes('users-username-boards') || route.name.includes('users-username-slug')) {
        if ($auth.loggedIn) {
            if (store?.state?.auth?.user?.saved_ideas_setup_at) {
                if (route.name === 'tools-vision-boards-setup' || route.name === 'tools-vision-boards-setup-step')
                    redirect(`/users/${store?.state?.auth?.user?.username}/boards`)
            } else if (route.name === 'tools-vision-boards-setup') {
                redirect('/tools/vision-boards/setup/1')
            } else if (route.name === 'users-username-boards' && store?.state?.auth?.user?.username === route.params?.username) {
                redirect('/tools/vision-boards/setup/1')
            } else if (route.name === 'users-username-slug') {
                redirect('/tools/vision-boards/setup/1')
            }
        } else if (route.name !== 'tools-vision-boards-setup') redirect('/tools/vision-boards/setup')
    }

    if (route.name.includes('vendor-manager')) {
        if ($auth.loggedIn) {
            if (route.name !== 'tools-vendor-manager' && route.name !== 'tools-vendor-manager-hired' && route.name !== 'users-username-settings-type')
                redirect('/tools/vendor-manager')
        } else if (route.name !== 'tools-vendor-manager-setup') redirect('/tools/vendor-manager/setup')
    }
}

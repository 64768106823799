export default {
    props: {
        defaultOpen: Boolean,
        sticky: Boolean,
    },

    data() {
        return {
            isOpen: this.defaultOpen,
            watchRoute: true,
        }
    },

    mounted() {
        if (this.watchRoute) {
            this.$watch('$route', () => {
                if (!this.sticky) this.isOpen = false
            })
        }
    },

    methods: {
        open() {
            this.isOpen = true
        },
        close() {
            this.isOpen = false
        },
        toggle() {
            this.isOpen = !this.isOpen
        },
    },
}

import { render, staticRenderFns } from "./AdminNavigation.vue?vue&type=template&id=d87a246c&lang=pug"
import script from "./AdminNavigation.vue?vue&type=script&lang=js"
export * from "./AdminNavigation.vue?vue&type=script&lang=js"
import style0 from "./AdminNavigation.vue?vue&type=style&index=0&id=d87a246c&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyAdminNavigationItem: require('/app/components/admin/AdminNavigationItem.vue').default,LoverlyCollapse: require('/app/components/elements/Collapse.vue').default,LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default})
